/* PricingDashboard.css */
.pricing-dashboard {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .dashboard-header h1 {
    font-size: 2rem;
    color: #2c3e50;
    margin: 0;
  }
  
  .dashboard-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .search-filter {
    display: flex;
    gap: 0.5rem;
  }
  
  .search-input,
  .category-filter {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .search-input {
    width: 250px;
  }
  
  .category-filter {
    min-width: 150px;
  }
  
  .add-button {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-button:hover {
    background-color: #2980b9;
  }
  
  .status-message {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }
  
  .status-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .add-form-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid #e9ecef;
  }
  
  .add-form-container h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-size: 1.5rem;
  }
  
  .add-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #495057;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    grid-column: 1 / -1;
    margin-top: 1rem;
  }
  
  .cancel-button,
  .save-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancel-button {
    background-color: #f8f9fa;
    color: #495057;
    border: 1px solid #ddd;
  }
  
  .cancel-button:hover {
    background-color: #e9ecef;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    border: none;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .pricing-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .category-section {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  
  .category-title {
    background-color: #f8f9fa;
    margin: 0;
    padding: 1.25rem;
    border-bottom: 1px solid #e9ecef;
    color: #2c3e50;
    font-size: 1.5rem;
  }
  
  .packages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1.5rem;
  }
  
  .package-card {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e9ecef;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    padding: 1.25rem;
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .package-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .package-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .package-header h3 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.25rem;
    padding-right: 1rem;
  }
  
  .package-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .edit-button,
  .delete-button {
    padding: 0.4rem 0.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .edit-button {
    background-color: #f8f9fa;
    color: #495057;
    border: 1px solid #ddd;
  }
  
  .edit-button:hover {
    background-color: #e9ecef;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .package-details {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .price-details,
  .note,
  .metadata {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .label {
    font-weight: 500;
    color: #6c757d;
    font-size: 0.875rem;
  }
  
  .value {
    font-weight: 400;
    color: #212529;
  }
  
  .original-price,
  .discounted-price,
  .price-note,
  .sort-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .discounted-price .value {
    color: #28a745;
    font-weight: 500;
  }
  
  .edit-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .dashboard-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 1.25rem;
    color: #6c757d;
  }
  
  .dashboard-error {
    padding: 2rem;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem;
  }
  
  .no-results {
    padding: 3rem 0;
    text-align: center;
    color: #6c757d;
    font-size: 1.1rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dashboard-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .search-filter {
      width: 100%;
      flex-direction: column;
    }
    
    .search-input,
    .category-filter {
      width: 100%;
    }
    
    .add-button {
      width: 100%;
    }
    
    .form-row {
      grid-template-columns: 1fr;
    }
    
    .packages-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }

  /* Mobile Responsive Styles for PricingDashboard */
@media screen and (max-width: 767px) {
  .pricing-dashboard {
    padding: 1rem;
    width: 100%;
  }
  
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .dashboard-controls {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-filter {
    width: 100%;
    flex-direction: column;
  }
  
  .search-input,
  .category-filter {
    width: 100%;
  }
  
  .add-button {
    width: 100%;
    padding: 0.875rem;
  }
  
  .add-form {
    grid-template-columns: 1fr;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .form-actions {
    flex-direction: column;
  }
  
  .cancel-button,
  .save-button {
    width: 100%;
    margin-top: 0.5rem;
  }
  
  .packages-grid {
    grid-template-columns: 1fr;
  }
  
  .package-card {
    width: 100%;
  }
  
  .package-header {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .package-actions {
    width: 100%;
    justify-content: space-between;
  }
  
  .edit-button,
  .delete-button {
    width: 48%;
    text-align: center;
  }
  
  /* Fix for consistent rendering on different sized screens */
  .original-price,
  .discounted-price,
  .price-note,
  .sort-order {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
  
  /* Prevent horizontal scrolling */
  html, body {
    overflow-x: hidden;
    max-width: 100%;
  }
  
  /* Adjust font sizes for better readability on small screens */
  .dashboard-header h1 {
    font-size: 1.5rem;
  }
  
  .category-title {
    font-size: 1.25rem;
    padding: 1rem;
  }
  
  .package-header h3 {
    font-size: 1.125rem;
  }
}

/* Additional fixes for very small screens */
@media screen and (max-width: 320px) {
  .pricing-dashboard {
    padding: 0.75rem;
  }
  
  .package-card {
    padding: 1rem;
  }
  
  .form-group input,
  .form-group select {
    font-size: 0.875rem;
    padding: 0.625rem;
  }
}

/* Ensure touch targets are large enough */
@media (hover: none) and (pointer: coarse) {
  .edit-button,
  .delete-button,
  .add-button,
  .save-button,
  .cancel-button {
    min-height: 44px;
  }
  
  .search-input,
  .category-filter,
  .form-group input,
  .form-group select {
    min-height: 44px;
  }
}